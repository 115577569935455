(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/outcome/assets/javascripts/outcome.js') >= 0) return;  svs.modules.push('/components/lb-ui/outcome/assets/javascripts/outcome.js');
"use strict";


const {
  clx
} = svs.components.lbUtils;
const Outcome = _ref => {
  let {
    branding,
    className,
    dataTestId,
    hasUSign,
    isBestBetOutcome = false,
    isDisabled,
    isResult = false,
    isSelected,
    sign,
    shouldShowResult = false
  } = _ref;
  const isCorrectSelected = shouldShowResult && isResult && isSelected;
  const isCorrectNotSelected = shouldShowResult && !isCorrectSelected && isResult && !isBestBetOutcome;
  const outcomeClassNames = clx('pg_outcome', className, {
    'pg_outcome--disabled': isDisabled,
    'pg_outcome--selected': isSelected,
    'pg_outcome--correct': shouldShowResult && isResult,
    'pg_outcome--best-bet': isBestBetOutcome,
    'pg_outcome--correct-selected': isCorrectSelected,
    'pg_outcome--correct-not-selected': isCorrectNotSelected,
    ["pg_outcome--".concat(branding)]: branding
  });
  return React.createElement("span", {
    className: outcomeClassNames,
    "data-testid": dataTestId
  }, React.createElement("span", {
    className: "pg_outcome__sign"
  }, sign), hasUSign && React.createElement("span", {
    className: "pg_outcome--system"
  }, "U"));
};
setGlobal('svs.components.lbUi.outcome.Outcome', Outcome);

 })(window);